import { useState, useEffect } from "react";

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setShowBanner(true);
    } else if (consent === "accepted") {
      enableTracking();
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setShowBanner(false);
    enableTracking();
  };

  const handleReject = () => {
    localStorage.setItem("cookieConsent", "rejected");
    setShowBanner(false);
    disableTracking();
  };

  const enableTracking = () => {
    // Garante que o dataLayer esteja disponível
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
  
    // Ativa o rastreamento após consentimento do usuário
    gtag("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
      functionality_storage: "granted",
      personalization_storage: "granted",
      security_storage: "granted",
    });
  
    // Ativa o Google Ads agora que o usuário consentiu
    gtag("config", "AW-16676880589", { 
      send_page_view: true,
      cookie_flags: 'SameSite=None;Secure'
    });
    gtag("config", "G-GY3RGJ51EZ", {
      cookie_flags: 'SameSite=None;Secure'
    });
  
    // Adiciona dinamicamente o Google Tag Manager (GTM)
    const gtmScript = document.createElement("script");
    gtmScript.async = true;
    gtmScript.src = "https://www.googletagmanager.com/gtm.js?id=GTM-NPLV5NK6";
    document.head.appendChild(gtmScript);
  };

  const disableTracking = () => {
    // Desativa o rastreamento quando o usuário rejeita
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }

    gtag("consent", "update", {
      ad_storage: "denied",
      analytics_storage: "denied",
      functionality_storage: "denied",
      personalization_storage: "denied",
      security_storage: "denied",
    });
  };

  if (!showBanner) return null;

  return (
    <div style={styles.banner}>
      <p>Utilizamos cookies para melhorar sua experiência em nosso site. Ao continuar navegando, você concorda com nossa política de privacidade.</p>
      <div style={styles.buttonContainer}>
        <button style={styles.acceptBtn} onClick={handleAccept}>Aceitar</button>
        <button style={styles.rejectBtn} onClick={handleReject}>Recusar</button>
      </div>
    </div>
  );
};

const styles = {
  banner: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    background: "rgba(0, 0, 0, 0.9)",
    color: "white",
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    zIndex: 1000,
  },
  buttonContainer: {
    display: "flex",
    gap: "10px",
  },
  acceptBtn: {
    background: "#487958",
    color: "white",
    border: "none",
    padding: "8px 16px",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "#3a5f45",
    }
  },
  rejectBtn: {
    background: "transparent",
    color: "white",
    border: "1px solid white",
    padding: "8px 16px",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.1)",
    }
  },
};

export default CookieConsent;
