import React from 'react';
import './instrutores.scss';

function Instrutores() {

    const handleWhatsAppClick = (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        const url = 'https://wa.me/551123660975?text=Oi!%20Gostaria%20de%20receber%20atendimento.';

        if (window.gtag_report_conversion) {
            window.gtag_report_conversion(url);
        } else {
            window.open(url, "_blank", "noopener,noreferrer");
        }
    };

    return(
        <section id="instrutores">
            <div className='leftImgMobile'></div>
            <div className="instrutores">
                
                <div className='leftImg'></div>

                <div className="rightBoard">
                    <div className='logoText'>
                        <p className='dizeres'>"O Studio Corpo & Alma foi totalmente planejado para entregar resultado aos nossos pacientes."</p>
                        <h1 className='titulo'>VENHA FAZER PILATES</h1>                    
                    </div>
                    
                    <input 
                        type="button"
                        onClick={handleWhatsAppClick}
                        className="btnAgendarInst"
                        value="Agende uma aula experimental grátis"
                    />
                </div> 
            </div>            
        </section>
    );
}

export default Instrutores;
