import React, { Suspense, lazy } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import './App.css';
import Menu from './components/content/menu';
import Main from './components/content/main';
import Rosto from './components/content/rosto';
import Servicos from './components/content/servicos';
import Instrutores from './components/content/instrutores';
import Footer from './components/content/footer';
import Local from './components/content/local';
import Avaliacoes from './components/content/avaliacoes';
import Valores from './components/content/valores';
import Espaco from './components/content/espaco';
import { useEffect } from 'react';
import { useState } from 'react';
import CookieConsent from './components/CookieConsent';

// Lazy load components that are not immediately visible
const LazyAvaliacoes = lazy(() => import('./components/content/avaliacoes'));
const LazyValores = lazy(() => import('./components/content/valores'));

// Declare gtag as a global function
declare global {
    interface Window {
        gtag_report_conversion: (url: string) => void;
    }
}

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ativaCor, setAtivaCor] = useState(false);

  useEffect(() => {
    // Abrir a modal automaticamente após 2 segundos
    const timer = setTimeout(() => {
      setIsModalOpen(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    function posicaoScroll() {
      setAtivaCor(window.scrollY > 20);
    }

    window.addEventListener('scroll', posicaoScroll);
    return () => window.removeEventListener('scroll', posicaoScroll);
  }, []);

  return (
    <HelmetProvider>
      <div className="App">
        <Helmet>
          <title>Studio Corpo & Alma - Pilates em Pirituba</title>
          <meta name="description" content="Studio de Pilates em Pirituba, São Paulo. Aulas personalizadas, acupuntura, pilates para gestantes, pilates para idosos e mais. Agende sua aula experimental!" />
          <meta name="keywords" content="pilates, pirituba, acupuntura, pilates para gestantes, pilates para idosos, exercícios, saúde, bem-estar" />
          <meta property="og:title" content="Studio Corpo & Alma - Pilates em Pirituba" />
          <meta property="og:description" content="Studio de Pilates em Pirituba, São Paulo. Aulas personalizadas, acupuntura, pilates para gestantes, pilates para idosos e mais." />
          <meta property="og:type" content="website" />
          <link rel="canonical" href="https://studiocorpoealma.com.br" />
        </Helmet>

        <CookieConsent />
        <Menu acao={ativaCor}/>
        <Rosto/>
        <main className="content">
          <Main/>        
          <Instrutores/>     
          <Espaco/>               
          <Servicos/> 
          <Local/> 
          <Suspense fallback={<div>Carregando...</div>}>
            <LazyAvaliacoes/>  
          </Suspense>
        </main>   
        <Footer/>
      </div>
    </HelmetProvider>
  );
}

export default App;
