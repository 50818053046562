import React from 'react';
import './style.scss'
import Logo from "../../assets/imagens/logo_corpo_e_alma.png";

class Rosto extends React.Component {
    handleWhatsAppClick = (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        const url = "https://wa.me/551123660975?text=Oi!%20Gostaria%20de%20receber%20atendimento.";

        if (window.gtag_report_conversion) {
            window.gtag_report_conversion(url);
        } else {
            window.open(url, "_blank", "noopener,noreferrer");
        }
    };

    render() {
        return (
            <div className="menu">
                <div className="logo">
                    <img src={Logo} alt="Studio Corpo & Alma" />
                </div>

                <div className="btn">
                    <input
                        type="button"
                        onClick={this.handleWhatsAppClick}
                        className="btnAgendar"
                        value="Agende uma aula experimental grátis"
                    />
                </div>
            </div>
        );
    }
}

export default Rosto;
