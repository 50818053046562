import React from 'react';
import WhatsappIcon from '../../assets/icons/whatsapp.svg';
import './style.scss';


const WhatsAppLink: React.FC = () => {
    const handleWhatsAppClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        window.gtag_report_conversion('https://wa.me/551123660975?text=Oi!%20Gostaria%20de%20receber%20atendimento.');
    };

    return (
        <a className="whatsapp" href="https://wa.me/551123660975?text=Oi!%20Gostaria%20de%20receber%20atendimento." target="_blank" onClick={handleWhatsAppClick}>
            <img src={WhatsappIcon} alt="Fale Conosco pelo WhatsApp" />
        </a>
    );
};

export default WhatsAppLink;